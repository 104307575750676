import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import "./assets/sass/style.scss"
import { LanguageProvider } from './context/LanguageContext';
import AppRouter from './router/AppRouter';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <LanguageProvider>
    <AppRouter/>
    </LanguageProvider>
  </>
);

