import React from "react";

const Preloader = () => {
  return (
    <>
      <div className="preload preload-container">
        <div className="middle" />
      </div>
    </>
  );
};

export default Preloader;
