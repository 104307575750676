import React, { useContext } from "react";
import Banner from "../others/Banner";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
const PrivatePolicy = () => {
  const { language} = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Banner title={t.private} />
      <section className="policyprivate">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <h5 className="text-center my-5">
                {t.privateDesc}
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivatePolicy;
