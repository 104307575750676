import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import axios from "axios";
import slugify from "slugify";
import LazyLoad from "react-lazy-load";
const NewCars = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [vehicle, setVehicle] = useState([]);
  const [engine, setEngine] = useState([]);
  const [transmission, setTransmission] = useState([]);
  useEffect(() => {
    axios
      .get("https://mycars.ae/api/v2/vehicle")
      .then((res) => {
        setVehicle(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("https://mycars.ae/api/v2/engineType")
      .then((res) => {
        setEngine(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://mycars.ae/api/v2/transmission")
      .then((res) => {
        const banData = res.data;
        setTransmission(banData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [vehicleImg, setVehicleImg] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeImgIndex, setActiveImgIndex] = useState(null);

  const handleMouseEnter = (index, imgIndex) => {
    setActiveIndex(index);
    setActiveImgIndex(imgIndex);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
    setActiveImgIndex(null);
  };

  useEffect(() => {
    if (vehicle) {
      vehicle.map((item) =>
        axios
          .get(`https://mycars.ae/api/v2/images/${item.id}`)
          .then((response) => {
            setVehicleImg((prevState) => [
              ...prevState,
              {
                carId: item.id,
                carImgs: response.data,
              },
            ]);
          })
      );
    }
  }, [vehicle]);

  const newCars = vehicle.filter((item) => item.new == 0);
  return (
    <section className="newcars">
      <div className="container-fluid" style={{ overflowX: "hidden" }}>
        <div className="row g-5">
          <div className="header-section-main pt-5">
            <h2 className="title-section-main" style={{ fontWeight: "bold" }}>
              {t.newcars}
            </h2>
            <Link
              to="/makes"
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({ top: 0 });
              }}
            >
              <div className="btn-read-more ">
                <a className="more-link" style={{ textDecoration: "none" }}>
                  <span>{t.viewdetails}</span>
                  <i className="icon-arrow-up-right2"></i>
                </a>
              </div>
            </Link>
          </div>
          <LazyLoad>
          <Swiper
            spaceBetween={20}
            // slidesPerView={3}
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              998: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {newCars.map((fd, index) => {
              const engineFind = engine.find((i) => i.id == fd.engineType);
              const engineName = engineFind ? engineFind.name : "";
              const transmissionFind = transmission.find(
                (i) => i.id == fd.transmission
              );
              const transmissionName = transmissionFind
                ? transmissionFind.name
                : "";

              const carIdImages = vehicleImg.find((i) => i.carId == fd.id);
              let carImg =
                carIdImages && Array.isArray(carIdImages.carImgs)
                  ? carIdImages.carImgs
                  : "";
              const imgSrc = Array.isArray(carImg)
                ? `https://mycars.ae/autos/${fd.id}/${carImg[0]}`
                : "";
              const imgSrc1 = Array.isArray(carImg)
                ? `https://mycars.ae/autos/${fd.id}/${carImg[1]}`
                : "";
              const imgSrc2 = Array.isArray(carImg)
                ? `https://mycars.ae/autos/${fd.id}/${carImg[2]}`
                : "";

              return (
                <SwiperSlide key={index}>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-12 ">
                    <div className="swiper new-cars mybest-selling pb-5">
                      <div className="swiper-wrapper">
                        <div className="tf-car-service swiper-slide">
                          <div className="image">
                            <div className="stm-badge-top">
                              <div className="feature">
                                <span style={{ background: "#01999C" }}>
                                  {t.buyNow1}
                                </span>
                              </div>
                              <span style={{ borderRadius: "8px" }}>
                                {fd.year}
                              </span>
                            </div>
                            <div className="listing-images">
                              <div className="hover-listing-image">
                                <div className="wrap-hover-listing">
                                  {[imgSrc, imgSrc1, imgSrc2].map(
                                    (img, imgIndex) => (
                                      <div
                                        key={imgIndex}
                                        className={`listing-item  ${
                                          activeIndex == index &&
                                          activeImgIndex == imgIndex
                                            ? "active"
                                            : (activeIndex == null ||
                                                activeIndex !== index) &&
                                              imgIndex === 0
                                            ? "active"
                                            : ""
                                        }`}
                                        title={
                                          fd.make_name + " " + fd.model_name
                                        }
                                        onMouseEnter={() =>
                                          handleMouseEnter(index, imgIndex)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        <div className="images">
                                          {img && (
                                            <img
                                              loading="lazy"
                                              src={img}
                                              className="swiper-image tfcl-light-gallery "
                                              alt="images"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}

                                  <div className="bullet-hover-listing">
                                    {[imgSrc, imgSrc1, imgSrc2].map(
                                      (imgs, imgIndexs) => (
                                        <div
                                          className={`bl-item  ${
                                            activeIndex == index &&
                                            activeImgIndex == imgIndexs
                                              ? "active"
                                              : (activeImgIndex == null &&
                                                  imgIndexs == 0) ||
                                                (activeIndex !== index &&
                                                  imgIndexs == 0)
                                              ? "active"
                                              : ""
                                          }`}
                                        ></div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="title">
                              <NavLink
                                style={{ textDecoration: "none" }}
                                onClick={() => window.scrollTo({ top: 0 })}
                                to={`/autos/${slugify(
                                  `${fd.id}-${fd.make_name}-${fd.model_name}-${fd.year}`
                                ).toLowerCase()}`}
                              >
                                {fd.make_name} {fd.model_name}
                              </NavLink>
                            </h6>
                            <span className="price" style={{ fontSize: "17px" }}>
                              ${fd.price}
                            </span>
                            <div className="description">
                              <ul>
                                <li className="listing-information fuel">
                                  <i className="icon-gasoline-pump-1"></i>
                                  <div className="inner">
                                    <span>{t.engineType}</span>
                                    <p>{engineName}</p>
                                  </div>
                                </li>
                                <li className="listing-information size-engine">
                                  <i className="icon-Group1"></i>
                                  <div className="inner">
                                    <span>{t.mileage}</span>
                                    <p>{fd.mileage} KM</p>
                                  </div>
                                </li>
                                <li className="listing-information transmission">
                                  <i className="icon-gearbox-1"></i>
                                  <div className="inner">
                                    <span>{t.transmission}</span>
                                    <p>{transmissionName}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="bottom-btn-wrap d-flex justify-content-between">
                              <div className="btn-read-more">
                                <NavLink
                                  to={`/autos/${slugify(
                                    `${fd.id}-${fd.make_name}-${fd.model_name}-${fd.year}`
                                  ).toLowerCase()}`}
                                  style={{ textDecoration: "none" }}
                                  onClick={() => window.scrollTo({ top: 0 })}
                                >
                                  <a
                                    className="more-link"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <span>{t.viewdetails}</span>
                                    <i className="icon-arrow-right2"></i>
                                  </a>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-pagination"></div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          </LazyLoad>
        </div>
      </div>
    </section>
  );
};

export default NewCars;
