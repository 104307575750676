import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import axios from "axios";
import { NavLink } from "react-router-dom";
import slugify from "slugify";
import LazyLoad from "react-lazy-load";
const RecentlyAdded = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [make, setMake] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [engine, setEngine] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [model, setModel] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeIndex1, setActiveIndex1] = useState(null);
  const [activeImgIndex1, setActiveImgIndex1] = useState(null);
  useEffect(() => {
    setActiveIndex(model[0]);
  }, []);
  useEffect(() => {
    Promise.all([
      axios.get("https://mycars.ae/api/v2/vehicle"),
      axios.get("https://mycars.ae/api/v2/make"),
      axios.get("https://mycars.ae/api/v2/engineType"),
      axios.get("https://mycars.ae/api/v2/transmission"),
    ])
      .then(([vehicleData, makeData, engineData, transmissionData]) => {
        setVehicle(vehicleData.data);
        setMake(makeData.data);
        setEngine(engineData.data);
        setTransmission(transmissionData.data);
        setActiveIndex(makeData.data.slice(-5)[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClick = (id) => {
    setActiveIndex(id);
  };
  const filtered = vehicle.filter((i) => i.make === activeIndex);

  const handleMouseEnter = (index, ImgIndex) => {
    setActiveIndex1(index);
    setActiveImgIndex1(ImgIndex);
  };

  const handleMouseLeave = () => {
    setActiveIndex1(null);
    setActiveImgIndex1(null);
  };

  const [vehicleImg, setVehicleImg] = useState([]);

  useEffect(() => {
    if (vehicle) {
      vehicle.map((item) =>
        axios
          .get(`https://mycars.ae/api/v2/images/${item.id}`)
          .then((responses) => {
            setVehicleImg((prevState) => [
              ...prevState,
              {
                carID: item.id,
                carImgs: responses.data,
              },
            ]);
          })
          .catch((error) => {
            console.error("Error fetching images:", error);
          })
      );
    }
  }, [vehicle]);
  console.log(make,"make");
  return (
    <>
      <section className="resentlyadded">
        <div className="widget-populer-makes-h5">
          <div className="themesflat-container w-100">
            <div className="populer-makes">
              <ul
                className="nav nav-pills tab-car-service-v2 justify-content-center mb-30 mb-5"
                id="pills-tab-service-v2"
                role="tablist"
              >
                {make.slice(-5).map((item,index) => {
                  const vehicleModel = vehicle.filter((i) => i.make == item.id);
                  return (
                    <li className="nav-item" role="presentation" key={index}>
                      {vehicleModel.length > 0 && (
                        <button
                          className={`nav-link ${
                            item.id === activeIndex ? "active" : ""
                          }`}
                          onClick={() => handleClick(item.id)}
                        >
                          {" "}
                          {item.name}
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>

              <div className="tab-content" id="pills-tabContent-v2">
                <div
                  className="tab-pane fade show active"
                  id="pills-cadilliac-service-v2"
                  role="tabpanel"
                >
                  <LazyLoad>
                  <div className="car-list-item">
                    {filtered.slice(0, 3).map((item, index) => {
                      const engineName = engine.find(
                        (i) => i.id == item.engineType
                      );
                      const transmissionName = transmission.find(
                        (i) => i.id == item.transmission
                      );
                      const carIDImages = vehicleImg.find(
                        (vi) => vi.carID === item.id
                      );
                      let carI =
                        carIDImages && Array.isArray(carIDImages.carImgs)
                          ? carIDImages.carImgs
                          : "";

                      const imgSrc = Array.isArray(carI)
                        ? `https://mycars.ae/autos/${item.id}/${carI[0]}`
                        : "";
                      const imgSrc1 = Array.isArray(carI)
                        ? `https://mycars.ae/autos/${item.id}/${carI[1]}`
                        : "";
                      const imgSrc2 = Array.isArray(carI)
                        ? `https://mycars.ae/autos/${item.id}/${carI[2]}`
                        : "";

                      return (
                        <div className="tf-car-service" key={index}>
                          <div className="image">
                            <div className="stm-badge-top">
                              <div className="feature">
                                <span style={{ background: "#01999C" }}>
                                  {t.buyNow1}
                                </span>
                              </div>
                              <span style={{ borderRadius: "8px" }}>
                                {item.year}
                              </span>
                            </div>
                            <div className="listing-images">
                              <div className="hover-listing-image">
                                <div className="wrap-hover-listing">
                                  {[imgSrc, imgSrc1, imgSrc2].map(
                                    (img, imgIndex) => (
                                      <div
                                        className={`listing-item  ${
                                          activeIndex1 == index &&
                                          activeImgIndex1 == imgIndex
                                            ? "active"
                                            : (activeIndex1 == null ||
                                                activeIndex1 !== index) &&
                                              imgIndex === 0
                                            ? "active"
                                            : ""
                                        }`}
                                        title={
                                          item.make_name + " " + item.model_name
                                        }
                                        onMouseEnter={() =>
                                          handleMouseEnter(index, imgIndex)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        <div className="images">
                                          {img && (
                                            <img
                                            loading="lazy"
                                              src={img}
                                              className="swiper-image tfcl-light-gallery"
                                              alt="images"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}

                                  <div className="bullet-hover-listing">
                                    {[imgSrc, imgSrc1, imgSrc2].map(
                                      (imgs, imgIndexs) => (
                                        <div
                                          className={`bl-item  ${
                                            activeIndex1 == index &&
                                            activeImgIndex1 == imgIndexs
                                              ? "active"
                                              : (activeImgIndex1 == null &&
                                                  imgIndexs == 0) ||
                                                (activeIndex1 !== index &&
                                                  imgIndexs == 0)
                                              ? "active"
                                              : ""
                                          }`}
                                        ></div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <h6 className="title">
                              <a href="#" style={{ textDecoration: "none" }}>
                                <NavLink
                                  style={{ textDecoration: "none" }}
                                  onClick={() => window.scrollTo({ top: 0 })}
                                  to={`/autos/${slugify(
                                    `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
                                  ).toLowerCase()}`}
                                >
                                  {item.make_name} - {item.model_name}
                                </NavLink>
                              </a>
                            </h6>
                            <span className="price" style={{ fontSize: "17px" }}>
                              $ {item.price}
                            </span>
                            <div className="description">
                              <ul>
                                <li className="listing-information fuel">
                                  <i className="icon-gasoline-pump-1"></i>
                                  <div className="inner">
                                    <span>{t.engineType}</span>
                                    <p>{engineName.name}</p>
                                  </div>
                                </li>
                                <li className="listing-information size-engine">
                                  <i className="icon-Group1"></i>
                                  <div className="inner">
                                    <span>{t.mileage}</span>
                                    <p>{item.mileage} KM</p>
                                  </div>
                                </li>
                                <li className="listing-information transmission">
                                  <i className="icon-gearbox-1"></i>
                                  <div className="inner">
                                    <span>{t.transmission}</span>
                                    <p>{transmissionName.name}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="bottom-btn-wrap">
                              <div className="btn-read-more">
                                <NavLink
                                  to={`/autos/${slugify(
                                    `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
                                  ).toLowerCase()}`}
                                  style={{ textDecoration: "none" }}
                                  onClick={() => window.scrollTo({ top: 0 })}
                                >
                                  <a
                                    className="more-link"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <span>{t.viewdetails}</span>
                                    <i className="icon-arrow-right2"></i>
                                  </a>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentlyAdded;
