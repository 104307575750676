import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import ScrollToTop from "react-scroll-to-top";
import NotFound from "../components/others/NotFound";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import Product from "../pages/Product";
import ProductDetails from "../pages/ProductDetails";import About from "../components/home/About";
import PrivatePolicy from "../components/home/PrivatePolicy";
import Terms from "../components/home/Terms";
const AppRouter = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);
  if (loading) {
    return (
      <div className="preload preload-container">
        <div className="middle"></div>
      </div>
    );
  }
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blogdetails" element={<BlogDetails />}></Route>
          <Route path="/makes" element={<Product />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/:productId" element={<Product />}></Route>
          <Route path="/private-policy" element={<PrivatePolicy />}></Route>
          <Route path="/terms-of-use" element={<Terms />}></Route>
          <Route path="/makes/:productId" element={<Product />}></Route>
          <Route
            path="/autos/:productName"
            element={<ProductDetails />}
          ></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
        <ScrollToTop
          top={300}
          className="scrollToTop"
          component={<i className="fa-solid fa-arrow-up"></i>}
        />
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
