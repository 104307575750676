import React from 'react'

const Banner = ({title,desc}) => {
  return (
    <>
    <section className="banner">
    <div className="tf-page-title mt-10">
        <div className="themesflat-container full">
          <div className="page-title t-al-center">
            <span>{desc}</span>
            <h1 className="main-title">{title}</h1>
          </div>
        </div>
      </div>
    </section>
    
    </>
  )
}

export default Banner