import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import Preloader from "../components/others/Preloader";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import mycars from "../assets/images/logo/mycars.png";
import Swal from "sweetalert2";
import LazyLoad from 'react-lazy-load';
const ProductDetails = () => {
  const { productName } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [vehicle, setVehicle] = useState([]);
  const [details, setDetails] = useState(null);
  const [color, setColor] = useState([]);
  const [ban, setBan] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [engineType, setEngineType] = useState([]);
  const [image, setImage] = useState([]);

  const monthData = [
    { id: 0, month: 6 },
    { id: 1, month: 12 },
    { id: 2, month: 18 },
    { id: 3, month: 36 },
  ];

  useEffect(() => {
    Promise.all([
      axios.get("https://mycars.ae/api/v2/vehicle"),
      axios.get("https://mycars.ae/api/v2/color"),
      axios.get("https://mycars.ae/api/v2/ban"),
      axios.get("https://mycars.ae/api/v2/transmission"),
      axios.get("https://mycars.ae/api/v2/engineType"),
    ])
      .then(
        ([vehicleRes, colorRes, banRes, transmissionRes, engineTypeRes]) => {
          setVehicle(vehicleRes.data);
          setColor(colorRes.data);
          setBan(banRes.data);
          setTransmission(transmissionRes.data);
          setEngineType(engineTypeRes.data);
          setLoading(false);
        }
      )
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const foundDetails = vehicle.find(
    (item) =>
      slugify(
        `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
      ).toLowerCase() === productName
  );
  const [vehicleImg, setVehicleImg] = useState([]);

  useEffect(() => {
    if (foundDetails) {
      setLoading(true);
      axios
        .get(`https://mycars.ae/api/v2/images/${foundDetails.id}`)
        .then((imagesRes) => {
          const imageData = imagesRes.data;
          const formattedImages = imageData.map((item) => ({
            original: `https://mycars.ae/autos/${foundDetails.id}/${item}`,
            thumbnail: `https://mycars.ae/autos/${foundDetails.id}/${item}`,
            originalClass: "originalClass",
          }));
          setImage(formattedImages);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [foundDetails]);

  useEffect(() => {
    if (vehicle) {
      vehicle.map((item) =>
        axios
          .get(`https://mycars.ae/api/v2/images/${item.id}`)
          .then((responses) => {
            setVehicleImg((prevState) => [
              ...prevState,
              {
                carID: item.id,
                carImgs: responses.data,
              },
            ]);
          })
          .catch((error) => {
            console.error("Error fetching images:", error);
          })
      );
    }
  }, [vehicle]);

  const filterColor = color.find(
    (item) => item.id == (foundDetails && foundDetails.color)
  );
  const filterBan = ban.find(
    (item) => item.id == (foundDetails && foundDetails.ban)
  );
  const filterTransmission = transmission.find(
    (item) => item.id == (foundDetails && foundDetails.transmission)
  );
  const filterEngineType = engineType.find(
    (item) => item.id == (foundDetails && foundDetails.engineType)
  );
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeImgIndex, setActiveImgIndex] = useState(null);

  const handleMouseEnter = (index, ImgIndex) => {
    setActiveIndex(index);
    setActiveImgIndex(ImgIndex);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
    setActiveImgIndex(null);
  };

  // Meta Tag
  const metaTag =
    vehicle && vehicle.find((i) => i.id == (foundDetails && foundDetails.id));
  let metaImg =
    vehicleImg &&
    vehicleImg.find((i) => i.carID == (foundDetails && foundDetails.id));
  const metaNewTxt =
    foundDetails && (foundDetails.new == 0 ? "Yeni" : "Sürülmüş");

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  // ERROR MESSAGE
  const [nameErr, setNameErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [hidden, setHidden] = useState(false);
  
  const formSubmitted = (e) => {
    e.preventDefault();
    let isValid = true;
  
    const fieldsToCheck = [
      { value: name, error: setNameErr },
      { value: phone, error: setPhoneErr },
    ];
  
    fieldsToCheck.forEach((item) => {
      if (!item.value || item.value.trim() === "") {
        item.error(true);
        isValid = false;
      } else {
        item.error(false);
      }
    });
  
    if (isValid) {
      const formData = new FormData()
      formData.append('id',foundDetails && foundDetails.id)
      formData.append('name',name)
      formData.append('phone',phone)
      formData.append('value',value)
      formData.append('selectedMonth',selectedMonth)
      formData.append('priceMonth',priceMonth)
      
      axios
      .post("https://mycars.ae/api/v2/order",formData)
      .then((res) => {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: `${t.alert}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err, "XETA");
      });
      setHidden(true);
    }
  };
  
  const [calculate1, setCalculate] = useState(); // MAX
  const [calcMin, setCalcMin] = useState(); // MIN
  const [value, setValue] = useState(); // range positon
  useEffect(() => {
    // 10%
    let price = foundDetails && foundDetails.price;
    if (price) {
      let calculate = price - (price * 30) / 100; // max
      let calcMin = price - calculate; // min
      setCalcMin(calcMin);
      setCalculate(calculate);
      setValue(calcMin);
    }
  }, [foundDetails]);

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [priceMonth, setPriceMonth] = useState();

  const handleMonthClick = (month) => {
    const selectMonth = month;
    setSelectedMonth(month);
    const lastPrice = (
      (foundDetails && foundDetails.price - value) / selectMonth
    ).toFixed();
    setPriceMonth(lastPrice);
  };

  useEffect(() => {
    setPriceMonth(calculate1 / monthData[0].month);
    setSelectedMonth(monthData[0].month);
  }, [calculate1]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(value);
    setPhoneErr(false);
  };

  //  BUY NOW FORM
  const [nameB, setNameB] = useState();
  const [phoneB, setPhoneB] = useState();
  // ERROR MESSAGE
  const [nameBErr, setNameBErr] = useState(false);
  const [phoneBErr, setPhoneBErr] = useState(false);

  const buyNowSubmitted = (e) => {
    e.preventDefault();
    let isValid = true;
  
    const fieldsToCheck = [
      { value: nameB, error: setNameBErr },
      { value: phoneB, error: setPhoneBErr },
    ];
  
    fieldsToCheck.forEach((item) => {
      if (!item.value || item.value.trim() === "") {
        item.error(true);
        isValid = false;
      } else {
        item.error(false);
      }
    });
  
    if (isValid) {
      const formData = new FormData();
      formData.append("id", foundDetails && foundDetails.id);
      formData.append("name", nameB);
      formData.append("phone", phoneB);
      axios
        .post("https://mycars.ae/api/v2/order", formData)
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: `${t.alert}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setNameB("");
          setPhoneB("");
        })
        .catch((err) => {
          console.log(err, "XETA");
        });
    }
  };
  

  const handlePhoneB = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhoneB(value);
    setPhoneBErr(false);
  };

  useEffect(() => {
    if (selectedMonth !== "") {
      const lastPrice = (
        (foundDetails && foundDetails.price - value) / selectedMonth
      ).toFixed();
      setPriceMonth(lastPrice);
    }
  }, [selectedMonth, foundDetails, value]);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Ekran genişliğine göre showBullets değerini belirleme
  const showBullets = windowWidth >= 548;

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        foundDetails && (
          <>
            <Helmet>
              <title>
                {metaTag.make_name +
                  " " +
                  metaTag.model_name +
                  ", " +
                  metaTag.year +
                  " il" +
                  ", " +
                  metaNewTxt +
                  ", " +
                  metaTag.price +
                  "$" +
                  ", " +
                  foundDetails.engine +
                  "L"}
              </title>
              <meta
                property="og:title"
                content={
                  metaTag.make_name +
                  " " +
                  metaTag.model_name +
                  ", " +
                  metaTag.year +
                  " il" +
                  ", " +
                  metaNewTxt +
                  ", " +
                  metaTag.price +
                  "$" +
                  ", " +
                  foundDetails.engine +
                  "L"
                }
              />
              <meta
                property="og:description"
                content={
                  metaTag.make_name +
                  " " +
                  metaTag.model_name +
                  ", " +
                  metaTag.year +
                  " il" +
                  ", " +
                  filterColor.name +
                  ", " +
                  filterEngineType.name +
                  ", " +
                  filterTransmission.name +
                  ", " +
                  metaNewTxt +
                  ", " +
                  metaTag.price +
                  "$" +
                  ", " +
                  foundDetails.engine +
                  "L"
                }
              />
              <meta
                property="og:image"
                content={
                  metaImg &&
                  `https://mycars.ae/autos/${foundDetails && foundDetails.id}/${
                    metaImg.carImgs[0]
                  }`
                }
              />
              <meta property="og:url" content="https://mycars.ae"></meta>
              <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="widget-property-detail">
              <div className="themesflat-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="wrap-property-car flex">
                      <div className="box-1">
                        <div className="icon-box-info flex">
                          <div className="info-sale"></div>
                          <div className="info flex">
                            <span>{t.marka}:</span>
                            <span className="fw-4">{foundDetails.make_name}</span>
                          </div>
                          <div className="info flex">
                            <span>{t.model}:</span>
                            <span className="fw-4">{foundDetails.model_name}</span>
                          </div>
                          <div className="info flex">
                            <span>{t.color}:</span>
                            <span className="fw-4">{filterColor.name}</span>
                          </div>
                          <div className="info flex">
                            <span>{t.ban}:</span>
                            <span className="fw-4">{filterBan.name}</span>
                          </div>
                        </div>
                        <div className="title-heading">
                          {foundDetails.make_name} {foundDetails.model_name}{" "}
                          {foundDetails.year}
                        </div>
                      </div>
                      <div className="box-2 t-al-right">
                        <div className="price-wrap flex">
                          <p className="price-sale">${foundDetails.price}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-12 pb-5">
                    <LazyLoad>
                    <ImageGallery
                      items={image}
                      showPlayButton={false}
                      thumbnailPosition={"left"}
                      showNav={false}
                      useBrowserFullscreen={true}
                      showBullets={showBullets}
                      showFullscreenButton={true}
                      showIndex={true}
                      className="custom-fullscreen-button"
                    />
                    </LazyLoad>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="post-property">
                      <div className="wrap-car-overview wrap-style">
                        <h4 className="title">{t.overview}</h4>
                        <div className="listing-info">
                          <div className="row">
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Vector5"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.condition}:
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.new == 0
                                      ? "Yeni"
                                      : "Sürülmüş"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Group5"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.engineType}:
                                  </span>
                                  <p className="listing-info-value">
                                    {filterEngineType.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Vector-13"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.year}:
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.year}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Format-color-fill"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.color}:
                                  </span>
                                  <p className="listing-info-value">
                                    {filterColor.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-dashboard-2"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.mileage}:{" "}
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.mileage} KM
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Group-22"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.seats}:
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.place}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Vector-22"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.transmission} :
                                  </span>
                                  <p className="listing-info-value">
                                    {filterTransmission.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Group-31"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.hp}:
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.hp}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-engine-1"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.engineSize}:
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.engine}L
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-md-6 item">
                              <div className="inner listing-infor-box">
                                <div className="icon">
                                  <i className="icon-Group-31"></i>
                                </div>
                                <div className="content-listing-info">
                                  <span className="listing-info-title">
                                    {t.gear}:
                                  </span>
                                  <p className="listing-info-value">
                                    {foundDetails.gear}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wrap-car-overview wrap-style">
                        <h4 className="title">{t.carInfo}</h4>
                        <div className="listing-info">
                          <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12 item">
                              <div className="inner listing-iTnfor-box">
                                {foundDetails.desc}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="author-contact-listing-wrap">
                      <div className="author-contact-wrap d-flex justify-content-center">
                        <div
                          className="author-contact-info"
                          style={{ fontSize: "20px" }}
                        >
                          <p>{t.orderTitle}</p>
                        </div>
                      </div>
                      <form
                        className="form-contact-admin mb-3"
                        onSubmit={buyNowSubmitted}
                      >
                        <div className="group-form">
                          <input
                            name="name"
                            value={nameB}
                            type="text"
                            className={`${nameErr && "form-control"}`}
                            placeholder={t.name}
                            onChange={(e) => {
                              setNameB(e.target.value);
                              setNameBErr(false);
                            }}
                          />

                          {nameBErr && (
                            <span className="invalid_message ms-4">
                              {t.nameValid}
                            </span>
                          )}

                          <i className="icon-user-1-1"></i>
                        </div>
                        <div className="group-form">
                          <input
                            name="phone"
                            type="text"
                            value={phoneB}
                            onChange={handlePhoneB}
                            placeholder={`${t.phone}`}
                            className="mt-3"
                          />

                          {phoneBErr && (
                            <span className="invalid_message ms-4">
                              {t.nameValid}
                            </span>
                          )}
                          <i className="icon-Group-14 mt-3"></i>
                        </div>
                        <button type="submit" className="btnBuyNow">
                          {t.buynow}
                        </button>
                      </form>

                      <form onSubmit={formSubmitted}>
                        <button
                          type="button"
                          className="modalBtn py-3"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          % {t.parts}
                        </button>

                        <div
                          className={`modal fade ${hidden ? "hidden" : ""}`}
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          style={{
                            display: `${hidden ? "none" : ""}`,
                            backgroundColor: "#00000080",
                          }}
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div
                              className="modal-content"
                              style={{ background: "#F6F7FA" }}
                            >
                              <div className="modal-header">
                                <h1
                                  className="modal-title d-flex align-items-center justify-content-center"
                                  id="exampleModalLabel"
                                  style={{ fontSize: "15px" }}
                                >
                                  <img
                                    src={mycars}
                                    alt=""
                                    className="img-fluid"
                                    style={{ width: "60px" }}
                                  />
                                  <p className="mt-3 ms-2">
                                    {metaTag.make_name} {metaTag.model_name},{" "}
                                    {metaTag.year + " il"},{" "}
                                    {"$" + metaTag.price}, {metaNewTxt} ,
                                    {foundDetails.engine + "L"}
                                  </p>
                                </h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                />
                              </div>
                              <div className="modal-body">
                                <p
                                  style={{ fontSize: "small" }}
                                  className="text-secondary"
                                >
                                  {t.payment}
                                  <i
                                    className="ms-2 fa-solid fa-circle-exclamation"
                                    style={{ color: "#00C2CB" }}
                                  ></i>
                                  <div>
                                    <div className="mt-3 containerRange">
                                      <h6
                                        style={{
                                          color: "#000",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ${value}
                                      </h6>
                                      <input
                                        type="range"
                                        min={calcMin}
                                        max={calculate1}
                                        value={value}
                                        onChange={handleChange}
                                        className="mb-3 modalRange slider"
                                        style={{ zIndex: 1 }}
                                      />
                                    </div>
                                    <p>{t.credit}</p>
                                    <div className="container-fluid">
                                      <div className="row">
                                        {monthData.map((i) => (
                                          <div className="col-3 col-sm-4 col-md-3 col-lg-3 modalCol">
                                            <button
                                              className={`monthBtn ${
                                                selectedMonth == i.month
                                                  ? "activeMonth"
                                                  : ""
                                              }`}
                                              type="button"
                                              onClick={() =>
                                                handleMonthClick(i.month)
                                              }
                                              style={{
                                                fontSize: `${
                                                  language == "AZ"
                                                    ? "15px"
                                                    : "10px"
                                                }`,
                                              }}
                                            >
                                              <span>
                                                {i.month} {t.month}
                                              </span>
                                            </button>
                                          </div>
                                        ))}
                                        <h4 className="text-center my-4">
                                          {t.payment2}{" "}
                                          <span style={{ color: "#00C2CB" }}>
                                            ${priceMonth}
                                          </span>
                                        </h4>
                                        <div className="formBody py-3">
                                          <input
                                            type="text"
                                            placeholder={`${t.name}`}
                                            onChange={(e) => {
                                              setName(e.target.value);
                                              setNameErr(false);
                                            }}
                                            style={{
                                              border: "none",
                                              borderRadius: "0",
                                              borderBottom: "1px solid #00C2CB",
                                              background: "#F6F7FA",
                                            }}
                                          />
                                          {nameErr && (
                                            <span className="invalid_message ms-4">
                                              {t.nameValid}
                                            </span>
                                          )}
                                          <input
                                            type="text"
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            placeholder={`${t.phone}`}
                                            className="mt-3"
                                            style={{
                                              border: "none",
                                              borderRadius: "0",
                                              borderBottom: "1px solid #00C2CB",
                                              background: "#F6F7FA",
                                            }}
                                          />
                                          {phoneErr && (
                                            <span className="invalid_message ms-4">
                                              {t.nameValid}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn modalBtn py-3 btnBuyNow"
                                >
                                  {t.send}{" "}
                                  <i className="fa-regular fa-paper-plane ms-2"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
      <div className="container-fluid" style={{ overflowX: "hidden" }}>
        <div className="row">
          <LazyLoad>
          <Swiper
            spaceBetween={20}
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              998: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {vehicle.map((item, index) => {
              const engineFind = engineType.find(
                (i) => i.id == item.engineType
              );
              const transmissionFind = transmission.find(
                (i) => i.id == item.transmission
              );

              const carIDImages = vehicleImg.find((vi) => vi.carID === item.id);
              let carI =
                carIDImages && Array.isArray(carIDImages.carImgs)
                  ? carIDImages.carImgs
                  : "";
              const imgSrc = Array.isArray(carI)
                ? `https://mycars.ae/autos/${item.id}/${carI[0]}`
                : "";
              const imgSrc1 = Array.isArray(carI)
                ? `https://mycars.ae/autos/${item.id}/${carI[1]}`
                : "";
              const imgSrc2 = Array.isArray(carI)
                ? `https://mycars.ae/autos/${item.id}/${carI[2]}`
                : "";
              return (
                <SwiperSlide key={index}>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="widget-related-single-listing">
                      <div className="themesflat-container">
                        <div className="related-single-listing swiper">
                          <div className="swiper-wrapper">
                            <div className="listing-grid-item swiper-slide">
                              <div className="listing-item-image">
                                <div className="hover-listing-image  ">
                              <div className="d-flex justify-content-between mx-3">
                              <span  style={{borderRadius:"8px",background:"#01999C"}} className="mt-2 px-3 py-1 fs-6 text-white">{t.buyNow1}</span>
                              <span style={{borderRadius:"8px",background:"#01999C"}} className="mt-2 px-3 py-1 fs-6 text-white">{item.year}</span>
                              </div>
                                  <div className="wrap-hover-listing">
                                    {[imgSrc, imgSrc1, imgSrc2].map(
                                      (img, imgIndex) => (
                                        <div
                                          className={`listing-item  ${
                                            activeIndex == index &&
                                            activeImgIndex == imgIndex
                                              ? "active"
                                              : (activeIndex == null ||
                                                activeIndex !== index) && imgIndex === 0
                                              ? "active"
                                              : ""
                                          }`}
                                          title="Lexus LC Hybrid 2024"
                                          onMouseEnter={() =>
                                            handleMouseEnter(index, imgIndex)
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <div className="images">
                                            {img && (
                                              <img
                                                src={img}
                                                className="swiper-image tfcl-light-gallery"
                                                alt="images"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}

                                    <div className="bullet-hover-listing">
                                      {[imgSrc, imgSrc1, imgSrc2].map(
                                        (imgs, imgIndexs) => (
                                          <div
                                            className={`bl-item  ${
                                              activeIndex == index &&
                                              activeImgIndex == imgIndexs
                                                ? "active"
                                                : (activeImgIndex == null &&
                                                    imgIndexs == 0) ||
                                                  (activeIndex !== index &&
                                                    imgIndexs == 0)
                                                ? "active"
                                                : ""
                                            }`}
                                          ></div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <span className="feature">Featured</span>
                              </div>
                              <div className="listing-item-content">
                                <div className="listing-top-content">
                                  <h6 className="title">
                                    <NavLink
                                      to={`/autos/${slugify(
                                        `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
                                      ).toLowerCase()}`}
                                      style={{ textDecoration: "none" }}
                                      onClick={() =>
                                        window.scrollTo({ top: 0 })
                                      }
                                    >
                                      {item.make_name} - {item.model_name}
                                    </NavLink>
                                  </h6>
                                  <div className="review-wrap">
                                    <div className="rating">
                                      <i className="icon-Vector3"></i>
                                      <i className="icon-Vector3"></i>
                                      <i className="icon-Vector3"></i>
                                      <i className="icon-Vector3"></i>
                                      <i className="icon-Vector3"></i>
                                    </div>
                                    <span className="review">( 2 Reviews )</span>
                                  </div>
                                  <div className="description">
                                    <ul>
                                      <li className="listing-information fuel">
                                        <i className="icon-gasoline-pump-1"></i>
                                        <div className="inner">
                                          <span>Fuel type</span>
                                          <p>{engineFind.name}</p>
                                        </div>
                                      </li>
                                      <li className="listing-information size-engine">
                                        <i className="icon-Group1"></i>
                                        <div className="inner">
                                          <span>Mileage</span>
                                          <p>{item.mileage} KM</p>
                                        </div>
                                      </li>
                                      <li className="listing-information transmission">
                                        <i className="icon-gearbox-1"></i>
                                        <div className="inner">
                                          <span>Transmission</span>
                                          <p>{transmissionFind.name}</p>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="bottom-price-wrap">
                                  <div className="price-wrap">
                                    <p className="price-sale">$ {item.price}</p>
                                  </div>
                                  <div className="btn-read-more">
                                    <NavLink
                                      to={`/autos/${slugify(
                                        `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
                                      ).toLowerCase()}`}
                                      style={{ textDecoration: "none" }}
                                      onClick={() =>
                                        window.scrollTo({ top: 0 })
                                      }
                                    >
                                      <a
                                        className="more-link"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <span>{t.viewdetails}</span>
                                        <i className="icon-arrow-right2"></i>
                                      </a>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          </LazyLoad>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
