import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import Preloader from "../components/others/Preloader";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import ScrollToTop from "../components/others/ScrollToTop";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import LazyLoad from "react-lazy-load";

import ReactSlider from "react-slider";

const Product = () => {
  const { id, productId } = useParams();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [make, setMake] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [color, setColor] = useState([]);
  const [ban, setBan] = useState([]);
  const [engine, setEngine] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [childrenData, setChildrenData] = useState("");
  const [maxPrice, setMaxPrice] = useState([]);
  const [active, setActive] = useState(false);
  const [news, setNews] = useState(false);
  const [used, setUsed] = useState(false);
  const [clicked, setClicked] = useState("all");
  const [grid, setGrid] = useState("");
  const [gridActive, setGridActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);

  // Range
  const [values, setValues] = useState(null);

  const findId = make.find((item) => {
    return slugify(`${item.name}`).toLowerCase() == productId;
  });

  const handleClick = () => {
    setMobileActive(!mobileActive);
  };
  const [select, setSelect] = useState({
    model: null,
    marka: null,
    year: null,
    color: null,
    ban: null,
    price: 0,
    engine: null,
    transmission: null,
    selectPrice: null,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelect((prevSelect) => ({
      ...prevSelect,
      [name]: value,
    }));
    if (name === "model") {
      setSelect({
        model: value,
        marka: "all",
        year: null,
        color: null,
        ban: null,
        price: 0,
        engine: null,
        transmission: null,
        selectPrice: null,
      });
    }
    if (name == "price") {
      setActive(true);
    }
    if (name == "model") {
      const selectedModelId = value;
      const selectedModelChildren = make.find(
        (item) => item.id == selectedModelId
      );
      setChildrenData(selectedModelChildren);
    }
  };
  useEffect(() => {
    if (findId && findId.id) {
      const selectedModelChildren =
        make && make.find((item) => item.id == findId && findId.id);
      setChildrenData(selectedModelChildren);
      setSelect({
        model: findId && findId.id,
        marka: "all",
        year: null,
        color: null,
        ban: null,
        price: 0,
        engine: null,
        transmission: null,
        selectPrice: null,
      });
    }
  }, [make]);

  useEffect(() => {
    axios
      .get("https://mycars.ae/api/v2/make")
      .then((res) => {
        setLoading(false);
        const makeData = res.data;
        setMake(makeData);
      })
      .catch((err) => {
        setLoading(false);
      });
    axios
      .get("https://mycars.ae/api/v2/vehicle")
      .then((res) => {
        const vehicleData = res.data;
        setVehicle(vehicleData);
        const maxPrice = Math.max(...vehicleData.map((item) => item.price));
        
        if (values === null) {
          setValues([0, maxPrice]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://mycars.ae/api/v2/color")
      .then((res) => {
        const colorData = res.data;
        setColor(colorData);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://mycars.ae/api/v2/ban")
      .then((res) => {
        const banData = res.data;
        setBan(banData);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://mycars.ae/api/v2/engineType")
      .then((res) => {
        const banData = res.data;
        setEngine(banData);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://mycars.ae/api/v2/transmission")
      .then((res) => {
        const banData = res.data;
        setTransmission(banData);
      })
      .catch((err) => {
        console.log(err);
      });
    setGridActive(1);
  }, [findId && findId.id]);

  let filteredData = vehicle.filter((item) => {
    let modelFilter =
      select.model !== "all" && select.model !== null
        ? item.make == select.model
        : item.make !== null;
    let markaFilter =
      select.marka !== "all" && select.marka !== null
        ? item.model == select.marka
        : item.model !== null;
    let yearFilter =
      select.year !== "all" && select.year !== null
        ? item.year == select.year
        : item.year !== null;
    let colorFilter =
      select.color !== "all" && select.color !== null
        ? item.color == select.color
        : item.color !== null;
    let banFilter =
      select.ban !== "all" && select.ban !== null
        ? item.ban == select.ban
        : item.ban !== null;
    let priceFilter =
      values !== null
        ? item.price >= values[0] && item.price <= values[1]
        : item.price > 0;
    let engineType =
      select.engine !== "all" && select.engine !== null
        ? item.engineType == select.engine
        : item.engineType !== null;
    let transmission =
      select.transmission !== "all" && select.transmission !== null
        ? item.transmission == select.transmission
        : item.transmission !== null;
    let newsFilter = news ? item.new === "0" : true;
    let usedFilter = used ? item.new === "1" : true;
    return (
      modelFilter &&
      markaFilter &&
      yearFilter &&
      colorFilter &&
      banFilter &&
      priceFilter &&
      engineType &&
      transmission &&
      newsFilter &&
      usedFilter
    );
  });

  filteredData = filteredData.filter((item) => {
    return (
      item.make_name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      item.model_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  });

  if (select.selectPrice !== null && select.selectPrice == "asc") {
    filteredData.sort((a, b) => b.price - a.price);
  }
  if (select.selectPrice !== null && select.selectPrice == "desc") {
    filteredData.sort((a, b) => a.price - b.price);
  }

  if (select.selectPrice !== null && select.selectPrice == "Az") {
    filteredData.sort((a, b) => {
      const nameA = a.make_name.toUpperCase();
      const nameB = b.make_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (select.selectPrice !== null && select.selectPrice == "Za") {
    filteredData.sort((a, b) => {
      const nameA = a.make_name.toUpperCase();
      const nameB = b.make_name.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (vehicle.length > 0) {
      const price = vehicle.map((item) => item.price);
      let maxPrice = Math.max(...price);
      setMaxPrice(maxPrice);
    }
  }, [vehicle]);

  const [showProduct, setShowProduct] = useState(6);

  const uniqueItems = Array.from(new Set(vehicle.map((item) => item.year)));
  const itemsPerPage = showProduct;
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (selectedPage) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    setCurrentPage(selectedPage.selected);
  };
  const currentData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleActive = (activeBtn) => {
    setClicked(activeBtn);
    setSelect((prevSelect) => ({
      ...prevSelect,
      model: "all",
      marka: "all",
    }));
  };
  const [vehicleImg, setVehicleImg] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeImgIndex, setActiveImgIndex] = useState(null);

  const handleMouseEnter = (index, imgIndex) => {
    setActiveIndex(index);
    setActiveImgIndex(imgIndex);
  };
  const handleMouseLeave = () => {
    setActiveIndex(null);
    setActiveImgIndex(null);
  };
  useEffect(() => {
    if (vehicle) {
      vehicle.map((item) =>
        axios
          .get(`https://mycars.ae/api/v2/images/${item.id}`)
          .then((response) => {
            setVehicleImg((prevState) => [
              ...prevState,
              {
                carId: item.id,
                carImgs: response.data,
              },
            ]);
          })
          .catch((error) => {
            console.error("Error fetching images:", error);
          })
      );
    }
  }, [vehicle]);
  const vehicleResultId = vehicle.filter((i) => i.make == findId && findId.id);
  const showing = vehicleResultId ? vehicleResultId.length : null;

  // Meta Tag
  const helmetTitle = findId && vehicle.find((i) => i.make == findId.id);
  const metaTitle = helmetTitle ? helmetTitle.make_name : "";
  let metaDesc = findId && vehicle.find((i) => i.make == findId.id);
  const metaFindVehicle = findId && vehicle.find((i) => i.make == findId.id);
  let metaImg =
    vehicleImg &&
    findId &&
    vehicleImg.find((i) => i.carId == metaFindVehicle.id);

  let bannerId = findId && make.find((item) => item.id == findId.id);

  const handleSliderChange = (val) => {
    setValues(val);
  };


 console.log('range values',values);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <ScrollToTop />

          <Helmet>
            <title>{metaTitle ? metaTitle : t.cars}</title>
            <meta property="og:title" content={metaTitle} />
            <meta
              property="og:description"
              content={
                metaDesc &&
                metaDesc.make_name +
                  ", " +
                  metaDesc.model_name +
                  ", " +
                  metaDesc.price
              }
            />
            <meta
              property="og:image"
              content={
                metaImg &&
                `https://mycars.ae/autos/${metaFindVehicle.id}/${metaImg.carImgs[0]}`
              }
            />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <div className="widget-banner-car-listing banner-car-listing-list">
            <div className="themesflat-container full">
              <div className="banner-car-listing">
                <h1
                  className="title text-white"
                  style={{ fontSize: "50px", fontWeight: "600" }}
                >
                  {findId ? bannerId.name : t.allCars}
                </h1>
              </div>
            </div>
          </div>
          <section className="product">
            <div className="widget-car-listing-list">
              <div className="themesflat-container">
                <div className="container-fluid">
                  <div className="row car-listing-list">
                    <div className="col-md-12 col-lg-3">
                      <div className="search-filter-listing-car">
                        <div className="filter-header-list">
                          <h6 className="title-filter">{t.productSearch}</h6>
                          <div className="btn-filter" onClick={handleClick}>
                            <div className="d-flex justify-content-center align-items-center">
                              {mobileActive ? (
                                <i className="fa-solid fa-xmark px-2" />
                              ) : (
                                <i className="icon-Grid-view" />
                              )}
                            </div>
                          </div>
                        </div>
                        <form
                          id="filter-list-car-side-bar"
                          className={`list-filter ${
                            mobileActive && "d-inline-block"
                          }`}
                        >
                          <div className="form-group">
                            <div className="input-search-list">
                              <input
                                type="search"
                                className="form-control"
                                placeholder={t.productSearchHere}
                                onChange={(event) => {
                                  setSearch(event.target.value);
                                }}
                              />
                              <span
                                className="input-group-text "
                                id="search-addon"
                              >
                                <i className="icon-Vector-1"></i>
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="widget widget-price ">
                              <div className="caption flex-two">
                                <p className="price-range">{t.price}</p>
                                <div className="d-flex justify-content-between">
                                  <p>
                                    {t.min} : $ {vehicle && values !== null ? values[0] : ''}
                                  </p>
                                  <p>
                                    {t.max} : $ {vehicle && values !== null ? values[1] : ''}
                                  </p>
                                </div>
                                <ReactSlider
                                  className="rangeslider"
                                  onChange={handleSliderChange}
                                  min={0}
                                  value={values}
                                  max={Math.max(
                                    ...vehicle.map((item) => item.price)
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="group-select">
                              <select
                                name="model"
                                id=""
                                onChange={handleChange}
                                value={select.model || "all"}
                              >
                                <option value="all" className="text-secondary">
                                  {t.marka}
                                </option>
                                {make.map((item) => (
                                  <option
                                    value={item.id}
                                    selected={select.model == item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="group-select">
                              <select
                                value={select.marka || ""}
                                name="marka"
                                id=""
                                onChange={handleChange}
                                disabled={`${
                                  childrenData == null ? "disabled" : ""
                                }`}
                                style={{
                                  cursor: `${
                                    childrenData == null ? "not-allowed" : ""
                                  }`,
                                }}
                              >
                                <option
                                  value="all"
                                  className="text-secondary"
                                  selected
                                >
                                  {t.model} :
                                </option>
                                {childrenData &&
                                  childrenData.children.length > 0 &&
                                  childrenData.children.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                  ))}
                              </select>

                              <select
                                value={select.year || ""}
                                name="year"
                                onChange={handleChange}
                                id=""
                                className="mt-3"
                              >
                                <option value="all">{t.year} :</option>
                                {uniqueItems
                                  .sort((a, b) => b - a)
                                  .map((year) => {
                                    return <option value={year}>{year}</option>;
                                  })}
                              </select>
                              <select
                                value={select.color || ""}
                                name="color"
                                onChange={handleChange}
                                id=""
                                className="mt-3"
                              >
                                <option value="all">{t.color} :</option>
                                {color.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>

                              <select
                                value={select.ban || ""}
                                name="ban"
                                onChange={handleChange}
                                id=""
                                className="mt-3"
                              >
                                <option value="all">{t.ban} :</option>
                                {ban.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>

                              <select
                                value={select.engine || ""}
                                name="engine"
                                onChange={handleChange}
                                id=""
                                className="mt-3"
                              >
                                <option value="all">{t.engineType} :</option>
                                {engine.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>

                              <select
                                value={select.transmission || ""}
                                name="transmission"
                                onChange={handleChange}
                                id=""
                                className="mt-3"
                              >
                                <option value="all">{t.transmission} :</option>
                                {transmission.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-9  listing-list-car-wrap">
                      <form className="tf-my-listing-search">
                        <div className="row ">
                          <div className="col-md-6   d-flex justify-content-start align-items-center">
                            <p className="showing">
                              <span style={{ color: "#01999C" }}>
                                {" "}
                                {filteredData.length > 0 ||
                                filteredData.length == 0
                                  ? filteredData.length
                                  : vehicle.length}
                              </span>{" "}
                              {t.result} 1-{showProduct} {t.result2}
                            </p>
                          </div>
                          <div className="col-md-6 toolbar-search-list ">
                            <div className="group-select-recently d-flex">
                              <select
                                name=""
                                id=""
                                onChange={(e) => setShowProduct(e.target.value)}
                                className="me-4"
                                style={{ padding: "none" }}
                              >
                                <option value="" disabled selected>
                                  {t.row} :
                                </option>
                                <option value="6">6</option>
                                <option value="12">12</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                              </select>
                              <select
                                name="selectPrice"
                                id=""
                                onChange={handleChange}
                                style={{ width: "auto", height: "auto" }}
                              >
                                <option value="" disabled selected>
                                  {t.sort} :
                                </option>
                                <option value="Az">{t.sort1}</option>
                                <option value="Za">{t.sort2}</option>

                                <option value="asc">{t.sort4}</option>
                                <option value="desc">{t.sort3}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="tab-listing-all">
                          <div className="condition-tab-wrap tf-search-condition-tab">
                            <div className="nav" id="nav-tab" role="tablist">
                              <NavLink
                                to="/makes"
                                style={{ textDecoration: "none" }}
                              >
                                <a
                                  className={`btn-condition-filter ${
                                    clicked === "all" && !id ? "active" : ""
                                  } `}
                                  style={{ textDecoration: "none" }}
                                  onClick={() => {
                                    setNews(false);
                                    setUsed(false);
                                    handleActive("all");
                                  }}
                                >
                                  {t.all}{" "}
                                  <span className="number-list">
                                    ({vehicle.length})
                                  </span>
                                </a>
                              </NavLink>

                              <NavLink
                                to="/makes"
                                className="mx-5"
                                style={{ textDecoration: "none" }}
                              >
                                <a
                                  className={`btn-condition-filter ${
                                    clicked == "news" ? "active" : ""
                                  } `}
                                  style={{ textDecoration: "none" }}
                                  onClick={() => {
                                    setNews(true);
                                    setUsed(false);
                                    handleActive("news");
                                  }}
                                >
                                  {t.new}{" "}
                                  <span className="number-list">
                                    (
                                    {vehicle.filter((i) => i.new == "0").length}
                                    )
                                  </span>
                                </a>
                              </NavLink>

                              <NavLink
                                to="/makes"
                                style={{ textDecoration: "none" }}
                              >
                                <a
                                  className={`btn-condition-filter ${
                                    clicked == "used" ? "active" : ""
                                  } `}
                                  style={{ textDecoration: "none" }}
                                  onClick={() => {
                                    setUsed(true);
                                    setNews(false);
                                    handleActive("used");
                                  }}
                                >
                                  {t.used}{" "}
                                  <span className="number-list">
                                    {" "}
                                    (
                                    {vehicle.filter((i) => i.new == "1").length}
                                    )
                                  </span>
                                </a>
                              </NavLink>
                            </div>
                          </div>
                          <div className="toolbar-list">
                            <div className="form-group">
                              <a
                                className={`btn-display-listing-grid ${
                                  gridActive == 1 ? "active" : ""
                                }`}
                                onClick={() => {
                                  setGrid("");
                                  setGridActive(1);
                                }}
                              >
                                <i className="icon-th-list"></i>
                              </a>
                            </div>
                            <div className="form-group">
                              <a
                                className={`btn-display-listing-list ${
                                  gridActive == 2 ? "active" : ""
                                }`}
                                style={{ textDecoration: "none" }}
                                onClick={() => {
                                  setGrid("listing-list-car-list");
                                  setGridActive(2);
                                }}
                              >
                                <i className="icon-line-height"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div
                        className="tab-content container"
                        id="nav-listing-car"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="all"
                          role="tabpanel"
                          aria-labelledby="all-tab"
                          tabindex="0"
                        >
                          <LazyLoad>
                            <div className="row">
                              <div
                                className={`listing-list-car-grid  col-12 ${grid} `}
                              >
                                {currentData.length == 0 ? (
                                  <h5>{t.noresult}.</h5>
                                ) : (
                                  currentData
                                    .filter((item) => {
                                      if (search == "") {
                                        return item;
                                      } else if (
                                        item.make_name
                                          .toLocaleLowerCase()
                                          .includes(
                                            search.toLocaleLowerCase()
                                          ) ||
                                        item.model_name
                                          .toLocaleLowerCase()
                                          .includes(search.toLocaleLowerCase())
                                      ) {
                                        return item;
                                      }
                                    })
                                    .map((item, index) => {
                                      const matchingEngine = engine.find(
                                        (e) => e.id === item.engineType
                                      );
                                      const engineName = matchingEngine
                                        ? matchingEngine.name
                                        : "";
                                      const findTransmission =
                                        transmission.find(
                                          (i) => i.id == item.transmission
                                        );
                                      const transmissionName = findTransmission
                                        ? findTransmission.name
                                        : "";
                                      const carIdImages = vehicleImg.find(
                                        (i) => i.carId == item.id
                                      );
                                      let carImg =
                                        carIdImages &&
                                        Array.isArray(carIdImages.carImgs)
                                          ? carIdImages.carImgs
                                          : "";
                                      const imgSrc = Array.isArray(carImg)
                                        ? `https://mycars.ae/autos/${item.id}/${carImg[0]}`
                                        : "";
                                      const imgSrc1 = Array.isArray(carImg)
                                        ? `https://mycars.ae/autos/${item.id}/${carImg[1]}`
                                        : "";
                                      const imgSrc2 = Array.isArray(carImg)
                                        ? `https://mycars.ae/autos/${item.id}/${carImg[2]}`
                                        : "";

                                      return (
                                        <>
                                          <div
                                            className="listing-grid-item "
                                            key={index}
                                          >
                                            <div className="listing-item-image ">
                                              <div className="hover-listing-image">
                                                <div className="wrap-hover-listing">
                                                  {[
                                                    imgSrc,
                                                    imgSrc1,
                                                    imgSrc2,
                                                  ].map((img, imgIndex) => (
                                                    <div
                                                      className={`listing-item  ${
                                                        activeIndex == index &&
                                                        activeImgIndex ==
                                                          imgIndex
                                                          ? "active"
                                                          : (activeIndex ==
                                                              null ||
                                                              activeIndex !==
                                                                index) &&
                                                            imgIndex === 0
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                      title={
                                                        item.make_name +
                                                        " " +
                                                        item.model_name
                                                      }
                                                      onMouseEnter={() =>
                                                        handleMouseEnter(
                                                          index,
                                                          imgIndex
                                                        )
                                                      }
                                                      onMouseLeave={
                                                        handleMouseLeave
                                                      }
                                                    >
                                                      <div className="images">
                                                        {img && (
                                                          <img
                                                            src={img}
                                                            className=""
                                                            alt="images"
                                                            loading="lazy"
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                  ))}

                                                  <div className="bullet-hover-listing">
                                                    {[
                                                      imgSrc,
                                                      imgSrc1,
                                                      imgSrc2,
                                                    ].map((imgs, imgIndexs) => (
                                                      <div
                                                        className={`bl-item  ${
                                                          activeIndex ==
                                                            index &&
                                                          activeImgIndex ==
                                                            imgIndexs
                                                            ? "active"
                                                            : (activeImgIndex ==
                                                                null &&
                                                                imgIndexs ==
                                                                  0) ||
                                                              (activeIndex !==
                                                                index &&
                                                                imgIndexs == 0)
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                      ></div>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="listing-item-content">
                                              <div className="listing-top-content">
                                                <h6 className="title">
                                                  <a
                                                    href="#"
                                                    style={{
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    <NavLink
                                                      to={`/autos/${slugify(
                                                        `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
                                                      ).toLowerCase()}`}
                                                      onClick={() =>
                                                        window.scrollTo({
                                                          top: 0,
                                                        })
                                                      }
                                                      style={{
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      {item.make_name}{" "}
                                                      {item.model_name}
                                                    </NavLink>
                                                  </a>
                                                </h6>
                                                <div className="review-wrap">
                                                  <div className="rating">
                                                    <i className="icon-Vector3"></i>
                                                    <i className="icon-Vector3"></i>
                                                    <i className="icon-Vector3"></i>
                                                    <i className="icon-Vector3"></i>
                                                    <i className="icon-Vector3"></i>
                                                  </div>
                                                  <span className="review">
                                                    {/* ( 2 Reviews ) */}
                                                  </span>
                                                </div>
                                                <div className="description">
                                                  <ul>
                                                    <li className="listing-information fuel">
                                                      <i className="icon-gasoline-pump-1"></i>
                                                      <div className="inner">
                                                        <span>
                                                          {t.engineType}
                                                        </span>
                                                        {engineName && (
                                                          <p>{engineName}</p>
                                                        )}
                                                      </div>
                                                    </li>
                                                    <li className="listing-information size-engine">
                                                      <i className="icon-Group1"></i>
                                                      <div className="inner">
                                                        <span>{t.mileage}</span>
                                                        <p>{item.mileage} KM</p>
                                                      </div>
                                                    </li>
                                                    <li className="listing-information transmission">
                                                      <i className="icon-gearbox-1"></i>
                                                      <div className="inner">
                                                        <span>
                                                          {t.transmission}
                                                        </span>
                                                        <p>
                                                          {transmissionName}
                                                        </p>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className="bottom-price-wrap">
                                                <div className="price-wrap mt-3">
                                                  <p className="price-sale">
                                                    ${item.price}
                                                  </p>
                                                </div>
                                                <NavLink
                                                  to={`/autos/${slugify(
                                                    `${item.id}-${item.make_name}-${item.model_name}-${item.year}`
                                                  ).toLowerCase()}`}
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                  onClick={() =>
                                                    window.scrollTo({ top: 0 })
                                                  }
                                                >
                                                  <div className="btn-read-more">
                                                    <a
                                                      className="more-link"
                                                      style={{
                                                        textDecoration: "none",
                                                      }}
                                                    >
                                                      <span>
                                                        {t.viewdetails}
                                                      </span>
                                                      <i className="icon-arrow-right2"></i>
                                                    </a>
                                                  </div>
                                                </NavLink>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                )}
                              </div>
                            </div>
                          </LazyLoad>

                          <div
                            className={`pagination_main my-5 ${
                              currentData.length == 0 ? "d-none" : ""
                            }`}
                          >
                            <ReactPaginate
                              pageCount={Math.ceil(
                                filteredData.length / itemsPerPage
                              )}
                              pageRangeDisplayed={2}
                              marginPagesDisplayed={2}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageClassName={"inActive"}
                              breakLabel={"..."}
                              nextLabel={
                                <i className="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i className="fa-solid fa-angles-left"></i>
                              }
                              breakClassName="break"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Product;
