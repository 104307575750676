import React, { useContext } from "react";
import Banner from "../others/Banner";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
const Terms = () => {
  const { language} = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Banner title={t.terms} />
      <section className="terms">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <h5 className="text-center my-4">
               {t.termsDesc}
              </h5>
              <p>
                {t.termsDesc1}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
