import React, { useContext, useEffect, useState } from "react";
import mycars from "../assets/images/logo/mycars.png";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import slugify from "slugify";

const Footer = () => {
  const [make, setMake] = useState([]);
  const [vehicle,setVehicle] = useState([]) ;
  const { language} = useContext(LanguageContext);
  const t = translations[language];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  useEffect(() => {
    axios
      .get("https://mycars.ae/api/v2/make")
      .then((res) => {
        setMake(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://mycars.ae/api/v2/vehicle")
      .then((res) => {
        setVehicle(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section className="footer mt-5">
        <footer id="footer" className="clearfix bg-footer2 pd-t81 re-hi">
          <div className="themesflat-container">

            <div className="row footer-main">
            <div className="row footer-top d-flex justify-content-center">
              <img
                src={mycars}
                alt="Logo"
                className="img-fluid"
                style={{ width: "170px", height: "auto" }}
              />
            </div>
              {make.map((item,i) => {
                const vehicleModel = vehicle.filter((i)=>i.make == item.id)
                return (
                  <div className={`col-lg-2  col-md-4 col-6`} key={i}>
                    <div className="widget widget-menu ">
                      <ul className="box-menu">
                        <li>
                          <NavLink
                            to={`/${slugify(`${item.name}`).toLowerCase()}`}
                            onClick={() => window.scrollTo({ top: 0 })}
                            style={{ textDecoration: "none" }}
                          >
                            {vehicleModel.length ? `${item.name} (${vehicleModel.length})` : ""} 
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row footer-bottom">
              <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <p className="text-white" style={{fontSize:"15px"}}>© {currentYear} Copyright <font color="#00C2CB">MyCars.ae</font> - {t.reserved}</p>
              </div>
              <div className="col-11 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <ul className="social-icon">
                  <li>
                    <a href="https://www.facebook.com/mycars.ae" style={{ textDecoration: "none" }}>
                      <i className="icon-6"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mycars_ae/" style={{ textDecoration: "none" }}>
                    <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ textDecoration: "none" }}>
                      <i className="icon-5"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ textDecoration: "none" }}>
                      <i className="icon-7"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12  col-md-12 col-lg-12 col-xl-4 col-xxl-4 ">
                <ul className="bottom-bar-menu">
                  <li>
                    <NavLink to="/about" onClick={()=>window.scrollTo({top:0})} style={{ textDecoration: "none" }}>
                      {t.about}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/private-policy" onClick={()=>window.scrollTo({top:0})} className="" style={{ textDecoration: "none" }}>
                    {t.private}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms-of-use" onClick={()=>window.scrollTo({top:0})} style={{ textDecoration: "none" }}>
                    {t.terms}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Footer;
