import React, { useContext } from "react";
import Banner from "../components/others/Banner";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const Blog = () => {

  const { language} = useContext(LanguageContext);
  const t = translations[language];
 
  const data = [
    { id: 0, title: "Title 1", desc: "Desc 1" },
    { id: 1, title: "Title 2", desc: "Desc 2" },
    { id: 2, title: "Title 3", desc: "Desc 3" },
    { id: 3, title: "Title 4", desc: "Desc 4" },
    { id: 4, title: "Title 5", desc: "Desc 5" },
    { id: 5, title: "Title 6", desc: "Desc 6" },
    { id: 6, title: "Title 7", desc: "Desc 7" },
    { id: 7, title: "Title 8", desc: "Desc 8" },
    { id: 8, title: "Title 9", desc: "Desc 9" }
  ];

  const [blog, setBlog] = useState(data);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (selectedPage) => {
    window.scrollTo({ top: 800, behavior: "smooth" });
    setCurrentPage(selectedPage.selected);
  };
  const currentData = blog.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <>
      <Banner desc="Save up to 15%" title={t.blogPage} />
      <section className="blog">
        <div className="flat-blog-list main-content">
          <div className="themesflat-container w1320">
            <div className="row">
              <div className="col-lg-8">
                <div className="flat-blog">
                  {currentData.map((fd) => (
                    <article className="entry format-standard">
                      <div className="feature-post">
                        <div className="category">
                          <ul className="flex">
                            <li>
                              <a href="#" style={{ textDecoration: "none" }}>
                                Business
                              </a>
                            </li>
                          </ul>
                        </div>
                        <img src="assets/images/blog/bl1.jpg" alt="image" />
                      </div>

                      <div className="main-post">
                        <div className="entry-meta">
                          <span className="author line">
                            <i className="icon-user"></i>
                            <a href="#" style={{ textDecoration: "none" }}>
                              Mehedii .Ha
                            </a>
                          </span>
                          <span className="comment line">
                            <i className="icon-2"></i>
                            <a href="#" style={{ textDecoration: "none" }}>
                              Coments (03)
                            </a>
                          </span>
                          <span className="time line">
                            <i className="icon-uniE971"></i>
                            <a href="#" style={{ textDecoration: "none" }}>
                              3 min Read
                            </a>
                          </span>
                        </div>
                        <h2 className="entry-title">
                          <a
                            href="blog-single.html"
                            style={{ textDecoration: "none" }}
                          >
                            The whimsically named Egg Canvas brainch {fd.id}
                          </a>
                        </h2>
                        <p className="entry-des">
                          There are many variations of passages of Lorem Ipsum
                          available, but majority have suffered teration in some
                          form, by injected humour, or randomised words which
                          don't look even slight believable. If you are going to
                          use a passage of Lorem Ipsum.
                        </p>
                       <NavLink to="/blogdetails" onClick={()=>{
                        window.scrollTo(0,0)
                       }} style={{textDecoration:"none"}}
                       >
                       <div className="btn-read-more">
                          <a
                            className="more-link"
                            href="blog-single.html"
                            style={{ textDecoration: "none" }}
                          >
                            <span>Read MOre</span>
                            <i className="icon-Path-90148"></i>
                          </a>
                        </div>
                       </NavLink>
                      </div>
                    </article>
                  ))}
                </div>
                <div>
                  <div className="pagination_main my-5">
                    <ReactPaginate
                      pageCount={Math.ceil(blog.length / itemsPerPage)}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageClassName={"inActive"}
                      breakLabel={"..."}
                      nextLabel={<i className="fa-solid fa-angles-right"></i>}
                      previousLabel={<i className="fa-solid fa-angles-left"></i>}
                      breakClassName="break"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="side-blog">
                  <div className="inner-side-bar pl-30">
                    <div className="widget widget-user t-al-center">
                      <div className="content-user">
                        <img src="assets/images/avatar/avt-blog.jpg" alt="" />
                        <h4>Rosalina D. Willaim</h4>
                        <span>Blogger/Photographer</span>
                        <p>
                          he whimsically named Egg Canvas is the design director
                          and photographer in New York. Why the nam
                        </p>
                        <div className="social-blog-dt">
                          <ul>
                            <li>
                              <a href="#" style={{ textDecoration: "none" }}>
                                <i className="icon-6"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" style={{ textDecoration: "none" }}>
                                <i className="icon-4"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" style={{ textDecoration: "none" }}>
                                <i className="icon-5"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" style={{ textDecoration: "none" }}>
                                <i className="icon-7"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="widget widget-search ">
                      <h3 className="widget-title">search here</h3>
                      <form
                        role="search"
                        method="get"
                        className="search-form"
                        action="https://themesflat.co/"
                      >
                        <label>
                          <input
                            type="search"
                            value=""
                            name="s"
                            className="s"
                            placeholder="Search here..."
                          />
                        </label>
                        <button type="submit" className="search-submit">
                          <i className="icon-search-1"></i>
                        </button>
                      </form>
                    </div>
                    <div className="widget widget-recent">
                      <h3 className="widget-title ">search here</h3>
                      <div className="recent-post-list">
                        <div className="list-recent">
                          <div className="recent-image">
                            <a href="#" style={{ textDecoration: "none" }}>
                              <img src="assets/images/blog/1.jpg" alt="Image" />
                            </a>
                          </div>
                          <div className="recent-info">
                            <div className="meta">
                              <i className="icon-1"></i>
                              <span>Jan 23,2022</span>
                            </div>
                            <h4 className="title">
                              <a href="#" style={{ textDecoration: "none" }}>
                                Budget Issues Force The Our To Become
                              </a>
                            </h4>
                          </div>
                        </div>
                        <div className="list-recent">
                          <div className="recent-image">
                            <a href="#" style={{ textDecoration: "none" }}>
                              <img src="assets/images/blog/2.jpg" alt="Image" />
                            </a>
                          </div>
                          <div className="recent-info">
                            <div className="meta">
                              <i className="icon-1"></i>
                              <span>Jan 23,2022</span>
                            </div>
                            <h4 className="title">
                              <a href="#" style={{ textDecoration: "none" }}>
                                The Best Products That Shape Fashion
                              </a>
                            </h4>
                          </div>
                        </div>
                        <div className="list-recent">
                          <div className="recent-image">
                            <a href="#" style={{ textDecoration: "none" }}>
                              <img src="assets/images/blog/3.jpg" alt="Image" />
                            </a>
                          </div>
                          <div className="recent-info">
                            <div className="meta">
                              <i className="icon-1"></i>
                              <span>Jan 23,2022</span>
                            </div>
                            <h4 className="title">
                              <a href="#" style={{ textDecoration: "none" }}>
                                The Best Products That Shape Fashion
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="widget widget-categories">
                      <h3 className="widget-title ">Catagories</h3>
                      <ul>
                        <li>
                          <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            className="category"
                          >
                            <span>Graphic Design</span>
                            <div className="number-category">03</div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            className="category active"
                          >
                            <span>Web Development</span>
                            <div className="number-category">03</div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            className="category"
                          >
                            <span>Digital Marketing</span>
                            <div className="number-category">03</div>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            className="category"
                          >
                            <span> UI/UX Development</span>
                            <div className="number-category">03</div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="widget widget-tags">
                      <h3 className="widget-title ">Catagories</h3>
                      <div className="tags_cloud_inner">
                        <a href="#" style={{ textDecoration: "none" }}>
                          Design
                        </a>
                        <a href="#" style={{ textDecoration: "none" }}>
                          marketing
                        </a>
                        <a href="#" style={{ textDecoration: "none" }}>
                          search
                        </a>
                        <a href="#" style={{ textDecoration: "none" }}>
                          branding
                        </a>
                        <a href="#" style={{ textDecoration: "none" }}>
                          startup
                        </a>
                        <a href="#" style={{ textDecoration: "none" }}>
                          tech
                        </a>
                        <a
                          href="#"
                          style={{ textDecoration: "none" }}
                          className="active"
                        >
                          landing{" "}
                        </a>
                        <a href="#" style={{ textDecoration: "none" }}>
                          coding
                        </a>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
