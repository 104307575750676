import React, { useContext } from 'react'
import ExploreAllCars from '../components/home/ExploreAllCars'
import RecentlyAdded from '../components/home/RecentlyAdded'
import NewCars from '../components/home/NewCars'
import UsedCars from '../components/home/UsedCars'
import { Helmet } from 'react-helmet'
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
const Home = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
    <Helmet>
      <title>{t.home}</title>
    </Helmet>
    <ExploreAllCars/>
    <RecentlyAdded/>
    <NewCars/>
    <UsedCars/>
    </>
  )
}

export default Home