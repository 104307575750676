import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import axios from "axios";
import slugify from "slugify";
const UsedCars = () => {
  const { language} = useContext(LanguageContext);
  const t = translations[language];
  
  const [vehicle,setVehicle] = useState([])
  const [engine,setEngine] = useState([])
  const [transmission,setTransmission] = useState([])
  useEffect(()=>{
    Promise.all([
      axios.get('https://mycars.ae/api/v2/vehicle'),
      axios.get('https://mycars.ae/api/v2/engineType'),
      axios.get('https://mycars.ae/api/v2/transmission')
    ])
    .then(([vehicleRes,engineRes,transmissionRes])=>{
       setVehicle(vehicleRes.data)
       setEngine(engineRes.data)
       setTransmission(transmissionRes.data)
    })
    .catch((err)=>{
      console.log(err) 
    })
  },[])

  const [vehicleImg,setVehicleImg] = useState([])
  
  useEffect(()=>{
    if (vehicle) {
      vehicle.map(item=>
        axios.get(`https://mycars.ae/api/v2/images/${item.id}`)
        .then((response)=>{
          setVehicleImg(prevState=>[
            ...prevState,
            {
              carId:item.id,
              carImgs:response.data
            }
          ])
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
      })
      )
    }
  },[vehicle])

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeImgIndex, setActiveImgIndex] = useState(null);
  
  const handleMouseEnter = (index, ImgIndex) => {
    setActiveIndex(index);
    setActiveImgIndex(ImgIndex);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
    setActiveImgIndex(null);
  };

  const [used,setUsed] = useState([])

 useEffect(()=>{
  const usedFilter = vehicle.filter((i)=>(i.used == '1'))
  setUsed(usedFilter)
 },[vehicle])
  return (
    <section className="newcars">
      <div className="container-fluid" style={{ overflowX: "hidden" }}>
        <div className="row g-5">
          {
            used.length > 0 && (
          <div className="header-section-main pt-5">
            <h2 className="title-section-main" style={{ fontWeight: "bold" }}>
              {t.usedcars}
            </h2>
            <Link
              to="/makes"
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.scrollTo({top:0});
              }}
            >
              <div className="btn-read-more ">
                <a className="more-link" style={{ textDecoration: "none" }}>
                  <span>{t.viewdetails}</span>
                  <i className="icon-arrow-up-right2"></i>
                </a>
              </div>
              
            </Link>
          </div>
            )
          }
          <Swiper
            spaceBetween={20}
            // slidesPerView={3}
            breakpoints={{
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              998: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {vehicle.map((fd,index) => {
             const engineFind = engine.find((i)=>i.id == fd.engineType);
             const engineName = engineFind ? engineFind.name : "" ;
             const transmissionFind = transmission.find((i)=>i.id == fd.transmission) ;
             const transmissionName = transmissionFind ? transmissionFind.name : "" ;
                    
              const carIDImages = vehicleImg.find(vi => vi.carId === fd.id);
              let carI = carIDImages && Array.isArray(carIDImages.carImgs) ? carIDImages.carImgs : '';
              const imgSrc = Array.isArray(carI) ? `https://mycars.ae/autos/${fd.id}/${carI[0]}` : '';
              const imgSrc1 = Array.isArray(carI) ? `https://mycars.ae/autos/${fd.id}/${carI[1]}`: '';
              const imgSrc2 = Array.isArray(carI) ? `https://mycars.ae/autos/${fd.id}/${carI[2]}`: '';   
             return (
              fd.new === '1' && (
                <SwiperSlide key={index}>
                <div className="col-12 col-sm-6 col-md-4 col-lg-12 ">
                  <div className="swiper new-cars mybest-selling pb-5">
                    <div className="swiper-wrapper">
                      <div className="tf-car-service swiper-slide">
                        <div className="image">
                          <div className="stm-badge-top">
                            <div className="feature">
                              {/* <span>Featured</span> */}
                            </div>
                            <span>{fd.year}</span>
                          </div>
                          <div className="listing-images">
                            <div className="hover-listing-image">
                            <div className="wrap-hover-listing">
                                    {[imgSrc, imgSrc1, imgSrc2].map(
                                      (img, imgIndex) => (
                                        <div
                                          className={`listing-item  ${
                                            activeIndex == index &&
                                            activeImgIndex == imgIndex
                                              ? "active"
                                              : activeIndex == null ||
                                                activeIndex !== index
                                              ? "active"
                                              : ""
                                          }`}
                                          title={fd.make_name + " " + fd.model_name}
                                          onMouseEnter={() =>
                                            handleMouseEnter(index, imgIndex)
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <div className="images">
                                            {img && (
                                              <img
                                                src={
                                                  (
                                                    activeIndex == null ||
                                                    activeIndex !== index
                                                      ? "active"
                                                      : ""
                                                  )
                                                    ? imgSrc
                                                    : img
                                                }
                                                className="swiper-image tfcl-light-gallery"
                                                alt="images"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}

                                    <div className="bullet-hover-listing">
                                      {[imgSrc, imgSrc1, imgSrc2].map(
                                        (imgs, imgIndexs) => (
                                            <div className={`bl-item  ${
                                            activeIndex == index &&
                                            activeImgIndex == imgIndexs
                                              ? "active"
                                              : ((activeImgIndex == null  && imgIndexs  == 0) || (activeIndex !== index && imgIndexs  == 0) )
                                              ? "active"
                                              : ""
                                          }`}
                                          ></div>
                                        )
                                      )}
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>
                        <div className="content">
                          <h6 className="title">
                            <NavLink  onClick={() => window.scrollTo({ top: 0 })} to={`/autos/${slugify(`${fd.id}-${fd.make_name}-${fd.model_name}-${fd.year}`).toLowerCase()}`} ></NavLink>
                          </h6>
                          <span className="price">${fd.price}</span>
                          <div className="description">
                            <ul>
                              <li className="listing-information fuel">
                                <i className="icon-gasoline-pump-1"></i>
                                <div className="inner">
                                  <span>Fuel type</span>
                                  <p>{engineName}</p>
                                </div>
                              </li>
                              <li className="listing-information size-engine">
                                <i className="icon-Group1"></i>
                                <div className="inner">
                                  <span>Mileage</span>
                                  <p>{fd.mileage} K.M</p>
                                </div>
                              </li>
                              <li className="listing-information transmission">
                                <i className="icon-gearbox-1"></i>
                                <div className="inner">
                                  <span>Transmission</span>
                                  <p>{transmissionName}</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="bottom-btn-wrap">
                            <div className="btn-read-more">
                             <NavLink onClick={() => window.scrollTo({ top: 0 })}  to={`/autos/${slugify(`${fd.id}-${fd.make_name}-${fd.model_name}-${fd.year}`).toLowerCase()}`} style={{textDecoration:"none"}}>
                             <a
                                className="more-link"
                                style={{ textDecoration: "none" }}
                              >
                                <span>{t.viewdetails}</span>
                                <i className="icon-arrow-right2"></i>
                              </a>
                             </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
               )
             ) 
            }
            )}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default UsedCars;
