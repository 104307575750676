import React, { useContext } from "react";
import jpg1 from "../../assets/images/banner/1.jpg";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import { NavLink } from "react-router-dom";
import banneraz from "../../assets/images/logo/banneraz.jpg"
import LazyLoad from "react-lazy-load";

const ExploreAllCars = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
    <LazyLoad>
    <img src={banneraz} alt="" className="img-fluid mb-5" loading="lazy" />
    </LazyLoad>
      {/* <section className="exploreallcars">
        <div class="widget-explore-car">
          <div class="themesflat-container">
            <div class="explore-car-wrap">
              <div class="header-section-main mb-46">
                <h2 class="title-section-main" style={{ fontWeight: "bold" }}>
                  {t.explorecars}
                </h2>
                <div class="btn-read-more me-2">
                  <NavLink to="/product" style={{ textDecoration: "none" }}>
                    <a class="more-link" style={{ textDecoration: "none" }}>
                      <span>{t.viewdetails}</span>
                      <i class="icon-arrow-up-right2"></i>
                    </a>
                  </NavLink>
                </div>
              </div>
              <div class="explore-car">
                <a href="#" class="explore-car-item">
                  <img src="assets/images/banner/1.jpg" alt="" />
                  <h5 class="title-explore">Sedan</h5>
                </a>
                <a href="#" class="explore-car-item">
                  <img src="assets/images/banner/2.jpg" alt="" />
                  <h5 class="title-explore">SUV</h5>
                </a>
                <a href="#" class="explore-car-item">
                  <img src="assets/images/banner/3.jpg" alt="" />
                  <h5 class="title-explore">Hatchback</h5>
                </a>
                <a href="#" class="explore-car-item">
                  <img src="assets/images/banner/4.jpg" alt="" />
                  <h5 class="title-explore">Hybrid</h5>
                </a>
                <a href="#" class="explore-car-item">
                  <img src="assets/images/banner/5.jpg" alt="" />
                  <h5 class="title-explore">Coupe</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ExploreAllCars;
