import React, { useContext } from "react";
import {  NavLink } from "react-router-dom";
import Banner from "./Banner";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
const NotFound = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];
  return (
    <section className="notfound">
      <Banner title={t.notfound} />

      <div className="widget-error-404">
                <div className="themesflat-container">
                    <div className="error-404 t-al-center">
                        <div className="thumb-404">
                            <img src="assets/images/page/404.png" className="img-fluid" alt=""/>
                        </div>
                        <div className="error-404-text">
                            <h3 style={{fontWeight:"bold"}}>{t.notfound1}</h3>
                            <p>{t.notfound2}</p>
                        </div>
                        <NavLink to="/" onClick={()=>{
                            window.scrollTo({top:0})
                        }}>
                        <div className="btn-main">
                            <a className="button_main_inner" style={{textDecoration:"none"}}>
                                <span>{t.home}</span>
                            </a>
                        </div>
                        </NavLink>
                    </div>
                </div>
            </div>
    </section>
  );
};

export default NotFound;
