import React, { useContext } from "react";
import Banner from "../others/Banner";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
const About = () => {
  const { language} = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Banner title={t.about} />
      <section className="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-9 col-md-9 col-lg-9">
              <h5 className="text-center my-5">
             {t.about1}
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
