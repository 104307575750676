import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo/logo.png";

import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import mycars from "../assets/images/logo/mycars.png"
import axios from "axios";
import slugify from "slugify";

const Header = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(false);
  const [show,setShow] = useState(false)

  const showOpen = ()=>{
    setShow(!show);
  }
  const handleClick = () => {
    setActive(!active);
  };

  const handleClick1 = () => {
    setActive1(!active1);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };


  const [make, setMake] = useState([]);
  const [vehicle,setVehicle] = useState([])
  useEffect(() => {
    axios
      .get("https://mycars.ae/api/v2/make")
      .then((res) => {
        setMake(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://mycars.ae/api/v2/vehicle")
      .then((res) => {
        setVehicle(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
  <>
    <header id="header" className="main-header header header-fixed ">
      <div className="header-lower">
        <div className="themesflat-container w1700">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-container flex justify-space align-center">
                <div className="logo">
                  <div className="logo-box flex align-items-center">
                    <NavLink to="/">
                      <img
                        src={mycars}
                        alt="Logo"
                        className="img-fluid" 
                        style={{width:"110px",height:"auto"}}
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="nav-outer flex align-center">
                  <nav className="main-menu show navbar-expand-md">
                    <div
                      className="navbar-collapse collapse clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li>
                          <NavLink to="/" style={{ textDecoration: "none" }}>
                            {t.home}
                          </NavLink>
                        </li>
                        <li className="dropdown2">
                          <a href="#" style={{ textDecoration: "none" }}>
                            {t.cars}
                          </a>
                          <ul className="flex-column" style={{left:`${language == "AZ" ? "-220%" : "-100%"}`}}>
                            <li className="ms-2">
                              <NavLink
                                to="/makes"
                                activeclassname="active"
                                style={{ textDecoration: "none" }}
                              >
                                {t.allCars}
                              </NavLink>
                            <hr />
                            </li>
                            <ul className="container dropnavcars">
                              <div className="row g-3">
                                {
                                  make.map((item,i)=>{
                                    const vehicleModel = vehicle.filter((i)=>i.make == item.id)
                                    return <div className="col-3" key={i}><NavLink  activeclassname="active" to={`/${slugify(`${item.name}`).toLowerCase()}`}>
                                      <li> {vehicleModel.length ? `${item.name} (${vehicleModel.length})` : ""} </li>
                                      </NavLink></div>
                                  })
                                }
                              </div>
                            </ul>
                          </ul>
                        </li>
                         {/* <li class="dropdown2">
                          <a href="#" style={{ textDecoration: "none" }}>
                            {t.news}{" "}
                          </a>
                          <ul>
                            <li>
                              <NavLink
                                to="/blog"
                                activeclassname="active"
                                style={{ textDecoration: "none" }}
                              >
                                {t.bloglist}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/blogdetails"
                                activeclassname="active"
                                style={{ textDecoration: "none" }}
                              >
                                {t.blogdetail}
                              </NavLink>
                            </li>
                          </ul>
                        </li>  */}
                        <li>
                          <NavLink
                            to="/contact"
                            style={{ textDecoration: "none" }}
                            activeclassname="active"
                          >
                            {t.contact}{" "}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="header-account d-flex justify-content-center align-items-center">
                  <div className="register ml--18 mb-3">
                    <select value={language} style={{padding:"5px",border:"none"}} onChange={handleLanguageChange}>
                      <option value="AZ">AZ</option>
                      <option value="RU">RU</option>
                    </select>
                  </div>
                  <p
                    style={{ fontWeight: "bold", color: "red" }}
                    className="mb-3 ms-2  "
                  >
                   <a href="tel:+(010) 456-11-11" style={{ fontWeight: "bold", color: "#00C2CB",textDecoration:"none" }}>(010) 456-11-11</a> 
                  </p>
                  {/* <NavLink to="/product"> */}
                  {/* </NavLink> */}
                </div>
                <div className="mobileSelect">
                    <select value={language} style={{padding:"5px"}} onChange={handleLanguageChange}>
                      <option value="AZ">AZ</option>
                      <option value="RU">RU</option>
                    </select>
                  <button
                    className="menuBtn"
                    type="button"
                    onClick={showOpen}
                  >
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`offcanvas offcanvas-start ${show ? "show" : "" }`}
        data-bs-backdrop="false"
        tabIndex="-1"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="staticBackdropLabel">
            <img src={mycars} alt="" className="img-fluid"  style={{width:"90px",height:"auto"}} />
          </h5>
          <button
            type="button"
            style={{ width: "auto", backgroundColor: "transparent" }}
            data-bs-dismiss="offcanvas"
            onClick={()=>{
              setShow(false)
            }}
          >
            <i
              className="fa-solid fa-xmark"
              style={{ color: "#00C2CB", fontSize: "20px" }}
            ></i>
          </button>
        </div>
        <div className="offcanvas-body padding">
          <div className="d-flex justify-content-between">
            <NavLink to="/" activeclassname="active" className="mb-3" onClick={showOpen}>
              {t.home}
            </NavLink>
          </div>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <p>{t.cars}</p>
              <p>
                <i
                  className={`fa-solid fa-caret-${active ? "up" : "down"}`}
                  style={{ color: `${active ? "red" : "#000"}` }}
                ></i>
              </p>
            </div>
            <div className={`dropdown ${active ? "active" : ""}`}>
              <ul>
                <li>
                  <NavLink to="/makes" className="mb-2" onClick={showOpen}>{t.allCars}</NavLink>
                  <hr className="text-dark" />
                </li>
                <li className="mt-2 d-flex flex-column" onClick={showOpen}>
                {
                  make.map((item,i)=>{
                    const vehicleModel = vehicle.filter((i)=>i.make == item.id)
                return    <NavLink to={`/${slugify(`${item.name}`).toLowerCase()}`} key={i}>
                  {vehicleModel.length ? `${item.name} (${vehicleModel.length})` : ""} 
                </NavLink>
                              })
                }
                </li>
              </ul>
            </div>
          </div>
          {/* <div>
            <div
              className="d-flex justify-content-between"
              style={{ cursor: "pointer" }}
              onClick={handleClick1}
            >
              <p>{t.news}</p>
              <p>
                <i
                  className={`fa-solid fa-caret-${active1 ? "up" : "down"}`}
                  style={{ color: `${active1 ? "red" : "#000"}` }}
                ></i>
              </p>
            </div>
            <div className={`dropdown ${active1 ? "active" : ""}`}>
              <ul>
                <li>
                  <NavLink to="/blog" className="mb-2" onClick={showOpen}>{t.bloglist}</NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink to="/blogdetails" onClick={showOpen}>{t.blogdetail}</NavLink>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="d-flex justify-content-between">
            <NavLink to="/contact" activeclassname="active" onClick={showOpen}>
              {t.contact}
            </NavLink>
          </div>
          <p style={{ fontWeight: "500", color: "red" }} className="mt-4">
            {" "}
            <a href="tel:+(010) 456-11-11" style={{ fontWeight: "bold", color: "#00C2CB",textDecoration:"none" }}><i className="fa-solid fa-phone text-dark me-2"></i> (010) 456-11-11</a>
           
          </p>
        </div>
      </div>
    </header>
  </>
  );
};

export default Header;
